import React from "react";

import logoLight from "../../assets/mm_logo_light.svg";
import logoDark from "../../assets/mm_logo_dark.svg";
import logoViolet from "../../assets/mm_logo_violet.svg";
import { links } from "../../shared/links";

const { homepage, dashboard } = links;

export enum LogoVariant {
  light = "light",
  dark = "dark",
  violet = "violet",
}
interface LogoProps {
  logoVariant: LogoVariant;
  className?: string;
  goToDashboard?: boolean;
}

const { light, dark, violet } = LogoVariant;

const Logo = ({
  logoVariant,
  className,
  goToDashboard,
}: LogoProps): JSX.Element => {
  const logoType = () => {
    switch (logoVariant) {
      case light:
        return logoLight;
      case dark:
        return logoDark;
      case violet:
        return logoViolet;
      default:
        return light;
    }
  };

  return (
    <a href={goToDashboard ? dashboard : homepage} className={className}>
      <img src={logoType()} alt="logo" height="49" width="160" />
    </a>
  );
};

export default Logo;

import React from "react";

import { Button as ButtonElement, ButtonVariant } from "./Button.styles";

interface ButtonProps {
  name: string;
  variant: ButtonVariant;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: JSX.Element;
  type?: "button" | "submit";
  disabled?: boolean;
  spinner?: boolean;
  className?: string;
}

const Button = ({
  name,
  variant,
  onClick,
  children,
  type = "button",
  disabled,
  spinner,
  className,
}: ButtonProps) => {
  return (
    <ButtonElement
      type={type}
      onClick={onClick}
      aria-label={name}
      variant={variant}
      spinner={spinner}
      disabled={disabled}
      className={className}
    >
      {children || name}
    </ButtonElement>
  );
};

export default Button;

import axios from "axios";

export function patchRequest(
  URL: string,
  data: object,
  accessToken: string | null
) {
  return axios.patch(URL, data, {
    headers: {
      Authorization: `JWT ${accessToken}`,
    },
  });
}

export function deleteRequest(URL: string, accessToken: string | null) {
  return axios.delete(URL, {
    headers: {
      Authorization: `JWT ${accessToken}`,
    },
  });
}

export function getRequest(URL: string, accessToken: string | null) {
  return axios.get(URL, {
    headers: {
      Authorization: `JWT ${accessToken}`,
    },
  });
}

export function postRequest(URL: string, data: object) {
  return axios.post(URL, data);
}

export function postRequestHeaders(
  URL: string,
  data: object,
  accessToken: string | null
) {
  return axios.post(URL, data, {
    headers: {
      Authorization: `JWT ${accessToken}`,
    },
  });
}

export const links = {
  homepage: "/",
  signup: "/signup",
  accountActivated: "/activate",
  lmaProduct: "/trade-borrow",
  cryptoMortgageProduct: "/crypto-mortgage",
  login: "/login",
  linkedin:
    "https://www.linkedin.com/company/moon-mortgage?trk=public_profile_experience-item_profile-section-card_image-click",
  twitter: "https://twitter.com/moonmortgageio?lang=en",
  terms: "/terms-of-service",
  kyc: "/kyc",
  kyw: "/kyw",
  declaration: "/declaration",
  aboutLoan: "/about-loan",
  loanApplication: "/loan-application",
  resetPassword: "/reset-password",
  newPassword: "/reset_password_confirm",
  dashboard: "/dashboard",
  borrow: "/borrow",
  accountInfo: "/account-information",
  trade: "/portfolio",
  contact: "/contact",
  privacy: "/policy-privacy",
  dataPrivacy: "/data-privacy",
  press: "/press-announcements",
  transaction: "/transaction-history",
  esign: "/e-sign",
  listOfStates: "/list-of-states",
  waitlist: "/waitlist",
};

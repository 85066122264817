import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppDispatch } from "../app/store";
import { selectStateGoogleUser } from "../features/GoogleSignUpSlice";
import { actions, selectStateLoginUser } from "../features/LoginSlice";
import { LoginProps } from "../features/models";
import { postRequest } from "../helpers/axios";
import { getLocalStorage, setLocalStorage } from "../helpers/storage";
import { UploadStatus } from "../shared/enum";
import { links } from "../shared/links";
import { Authorization } from "./Authorization";

const API_URL = process.env.REACT_APP_API_URL;

const { homepage } = links;

const RefreshToken = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const authorization = Authorization();
  const { FOUND, REMOVE } = UploadStatus;
  const loginState = useSelector(selectStateLoginUser);
  const googleUserState = useSelector(selectStateGoogleUser);
  const isSuccessLoginStatus = loginState?.loginStatus === FOUND;
  const isSuccessGoogleStatus = googleUserState?.uploadGoogleStatus === FOUND;
  const isRemovedLoginStatus = loginState?.loginStatus === REMOVE;

  const refresh = async (refreshToken: LoginProps) => {
    const localTokens = getLocalStorage("moonmortgageLogin");
    const isRemember = localStorage.getItem("moonmortgageRemember");

    const response = await postRequest(`${API_URL}/auth/jwt/refresh/`, {
      refresh: refreshToken?.refresh,
    })
      .then((res) => {
        const newTokens: LoginProps = {
          access: res.data.access as string,
          refresh: refreshToken.refresh as string,
        };
        if (
          localTokens &&
          isRemember &&
          (JSON.parse(isRemember) === "unremembered" ||
            JSON.parse(isRemember) === "remembered")
        ) {
          setLocalStorage("moonmortgageLogin", newTokens, isRemember);
        }

        dispatch(actions.updateUser(newTokens));

        return res.data;
      })
      .catch((error) => {
        dispatch(actions.resetUser());
        navigate(homepage);
        return error.message;
      });

    return response;
  };

  const clearRefreshInterval = (refreshInterval: number | null) => {
    if (refreshInterval) clearInterval(refreshInterval);
  };

  useEffect(() => {
    let refreshInterval: number | null = null;

    if (isRemovedLoginStatus && refreshInterval) {
      clearRefreshInterval(refreshInterval);
    }

    if (
      (authorization && !isRemovedLoginStatus) ||
      isSuccessLoginStatus ||
      isSuccessGoogleStatus
    ) {
      const refreshToken = isSuccessGoogleStatus
        ? {
            access: googleUserState.googleRegister.access,
            refresh: googleUserState.googleRegister.refresh,
          }
        : authorization || loginState.login;
      refresh(refreshToken).catch(() => {
        clearRefreshInterval(refreshInterval);
      });

      refreshInterval = window.setInterval(() => {
        refresh(refreshToken).catch(() => {
          clearRefreshInterval(refreshInterval);
        });
      }, 4000 * 60);
    }

    return () => {
      clearRefreshInterval(refreshInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return null;
};

export default RefreshToken;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { postRequest } from "../helpers/axios";
import { UploadStatus } from "../shared/enum";
import { SignupProps, StateProps, UserProps } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: SignupProps = {
  userInfoRegister: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
    prefix: "",
    phone: "",
    dataPrivacy: false,
    termsAndConditions: false,
    eSign: false,
  },
  uploadStatusRegister: UploadStatus.IDLE,
};

export const signupUserFetch = async (
  data: UserProps
): Promise<UserProps | null> => {
  const res = postRequest(`${API_URL}/auth/users/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });

  return res;
};

export const signupUser = createAsyncThunk(
  "signupUser",
  async (data: UserProps): Promise<UserProps | null> => {
    const response = await signupUserFetch(data);
    return response;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetSignup() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signupUser.pending, (state) => {
      return {
        ...state,
        UploadStatus: UploadStatus.LOADING,
        userInfoRegister: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          re_password: "",
          prefix: "",
          phone: "",
          dataPrivacy: false,
          termsAndConditions: false,
          eSign: false,
        },
      };
    });

    builder.addCase(
      signupUser.fulfilled,
      (state, action: PayloadAction<UserProps | null>) => {
        if (action.payload === null) {
          return state;
        }
        const uploadStatusRegister =
          action.payload.email && action.payload.email !== ""
            ? UploadStatus.FOUND
            : UploadStatus.NONE_FOUND;

        return {
          ...state,
          userInfoRegister: action.payload,
          uploadStatusRegister,
        };
      }
    );
  },
});

export const selectStateUser = (state: StateProps) => state.user;

export const actions = {
  ...userSlice.actions,
  signupUser,
};

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppDispatch } from "../../app/store";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-down-violet.svg";
import { actions } from "../../features/LoginSlice";
import { actions as actionsUserInfos } from "../../features/UserInfoSlice";
import { actions as actionsGoogle } from "../../features/GoogleSignUpSlice";
import { deleteLocalStorage } from "../../helpers/storage";
import { links } from "../../shared/links";
import {
  Break,
  LinkElementLogged,
  LinksContainerLogged,
  LinksLogged,
  MobileLinks,
  Namebox,
  NameContainerLogged,
  NameLogged,
  SignOut,
} from "./LoggedInLinks.styles";

const {
  homepage,
  dashboard,
  accountInfo,
  lmaProduct,
  cryptoMortgageProduct,
  contact,
  kyw,
} = links;

interface LoggedInLinksProps {
  userName: string | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  variant?: "main-nav";
  navbarscroll?: boolean;
}

const LoggedInLinks = ({
  userName,
  setIsOpen,
  variant,
  navbarscroll,
}: LoggedInLinksProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const signOut = () => {
    deleteLocalStorage("moonmortgageLogin");
    deleteLocalStorage("moonmortgageLoginGoogle");
    dispatch(actions.resetUser());
    dispatch(actionsGoogle.resetUserGoogle());
    dispatch(actionsUserInfos.resetUserInfo());
    setIsOpen(false);
    navigate(homepage);
  };

  const linksNav = useMemo(
    () => [
      { name: t("main.navigation.dashboard"), link: dashboard },
      { name: t("main.navigation.accountInfo"), link: accountInfo },
      { name: t("main.navigation.whitelistAddress"), link: kyw },
    ],
    [t]
  );

  const linksMainNavMobile = useMemo(
    () => [
      { name: t("main.navigation.home"), link: homepage },
      { name: t("main.navigation.tradeBorrow"), link: lmaProduct },
      {
        name: t("main.navigation.cryptoMortgage"),
        link: cryptoMortgageProduct,
      },
      { name: t("main.navigation.contact"), link: contact },
    ],
    [t]
  );

  const renderLinks = (
    linksElement: {
      name: string;
      link: string;
    }[]
  ) =>
    linksElement.map(({ name, link }) => (
      <LinkElementLogged to={link} key={name} variant={variant}>
        {name}
      </LinkElementLogged>
    ));

  return (
    <LinksContainerLogged
      variant={variant}
      scroll={navbarscroll ? navbarscroll.toString() : "false"}
    >
      <NameContainerLogged>
        <Namebox isLoaded={typeof userName === "string"}>
          <NameLogged
            to={dashboard}
            scroll={navbarscroll ? navbarscroll.toString() : "false"}
            variant={variant}
          >
            {userName}
          </NameLogged>
          <Arrow />
        </Namebox>
        <LinksLogged>
          {variant && (
            <>
              <MobileLinks>{renderLinks(linksMainNavMobile)}</MobileLinks>
              <Break variant={variant} />
            </>
          )}
          {renderLinks(linksNav)}
          <Break />
          <SignOut onClick={signOut} variant={variant}>
            {t("main.navigation.signOut")}
          </SignOut>
        </LinksLogged>
      </NameContainerLogged>
    </LinksContainerLogged>
  );
};

export default LoggedInLinks;

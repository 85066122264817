import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import main from "./translations/en/main.json";
import homepage from "./translations/en/homepage.json";
import lmaProduct from "./translations/en/lmaProduct.json";
import cryptoMortageProduct from "./translations/en/cryptoMortageProduct.json";
import terms from "./translations/en/termsOfService.json";
import form from "./translations/en/form.json";
import resetPassword from "./translations/en/resetPassword.json";
import dashboard from "./translations/en/dashboard.json";
import modal from "./translations/en/modal.json";
import borrow from "./translations/en/borrow.json";
import contactInfo from "./translations/en/contactInfo.json";
import trade from "./translations/en/trade.json";
import contactUs from "./translations/en/contactUs.json";
import modalWallets from "./translations/en/modalWallets.json";
import modalTrade from "./translations/en/modalTrade.json";
import modalPayment from "./translations/en/modalPayment.json";
import kyw from "./translations/en/kyw.json";
import modalClaimFunds from "./translations/en/modalClaimFunds.json";
import privacyPolicy from "./translations/en/privacyPolicy.json";
import glba from "./translations/en/glba.json";
import modalCancel from "./translations/en/modalCancel.json";
import press from "./translations/en/press.json";
import transactionHistory from "./translations/en/transactionHistory.json";
import errorMessage from "./translations/en/errorMessage.json";
import esign from "./translations/en/esign.json";
import listOfStates from "./translations/en/listOfStates.json";
import modalSignup from "./translations/en/modalSignup.json";
import waitlist from "./translations/en/waitlist.json";

i18n.use(initReactI18next).init({
  lng: "en-US",
  fallbackLng: "en",
  debug: false,
  resources: {
    en: {
      translation: {
        main,
        homepage,
        lmaProduct,
        cryptoMortageProduct,
        terms,
        form,
        resetPassword,
        dashboard,
        modal,
        borrow,
        contactInfo,
        trade,
        contactUs,
        modalWallets,
        modalTrade,
        modalPayment,
        kyw,
        modalClaimFunds,
        privacyPolicy,
        glba,
        modalCancel,
        press,
        transactionHistory,
        errorMessage,
        esign,
        listOfStates,
        modalSignup,
        waitlist,
      },
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

export default i18n;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { links } from "../../shared/links";
import { LinkButton } from "../LinkButton";
import { LogoVariant } from "../Logo/Logo";
import { LinkVariant } from "../LinkButton/LinkButton.styles";
import { Logo } from "../Logo";
import useOverflow from "../../hooks/useOverflow";
import { selectStateLoginUser } from "../../features/LoginSlice";
import { UploadStatus } from "../../shared/enum";
import { selectStateGoogleUser } from "../../features/GoogleSignUpSlice";
import { getUserInfo, selectStateUserInfo } from "../../features/UserInfoSlice";
import { LoggedInLinks } from "../LoggedInLinks";
import {
  HeaderContainer,
  LogoContainer,
  Container,
  Beam,
  Input,
  Label,
  HamburgerVariant,
} from "../../common/Header/Header.styles";
import {
  Header,
  Links,
  Item,
  Link,
  Buttons,
  ButtonElement,
  DesktopLinks,
} from "./Navigation.styles";
import { Button } from "../Button";
import { ButtonVariant } from "../Button/Button.styles";

const {
  homepage,
  lmaProduct,
  cryptoMortgageProduct,
  login,
  signup: signupLink,
} = links;
interface NavigationProps {
  buttonOnClick?: () => void;
}

const Navigation = ({ buttonOnClick }: NavigationProps): JSX.Element => {
  const loginState = useSelector(selectStateLoginUser);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const getAccessToken = loginState.login.access || null;
    if (getAccessToken) {
      dispatch(getUserInfo(getAccessToken));
    }
    return () => {};
  }, [dispatch, loginState.login.access]);

  const { t } = useTranslation();
  const { signup } = LinkVariant;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [navbarscroll, setNavbarScroll] = useState(false);
  const { dark, light } = LogoVariant;
  const { green } = HamburgerVariant;
  const { FOUND } = UploadStatus;
  const userInfo = useSelector(selectStateUserInfo);
  const loginStatus = loginState?.loginStatus;
  const isSuccessLoginStatus = loginStatus === FOUND;
  const googleUserState = useSelector(selectStateGoogleUser);
  const isSuccessGoogleStatus = googleUserState?.uploadGoogleStatus === FOUND;
  const [nameUser, setNameUser] = useState<string | null>(null);

  useOverflow(isOpen);

  const changeHeader = () => {
    if (window.scrollY >= 1) {
      setNavbarScroll(true);
    } else {
      setNavbarScroll(false);
    }
  };

  useEffect(() => {
    changeHeader();
    window.addEventListener("scroll", changeHeader);
    return () => {
      setNavbarScroll(false);
      window.removeEventListener("scroll", changeHeader);
    };
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.first_name) {
      setNameUser(userInfo.first_name);
    }
    return () => setNameUser(null);
  }, [userInfo]);

  const linksNav = (
    <Links>
      <Item>
        <Link
          className={(navData) => (navData.isActive ? "active" : "")}
          aria-label={t("main.navigation.home")}
          to={homepage}
          navbarscroll={navbarscroll.toString()}
        >
          {t("main.navigation.home")}
        </Link>
      </Item>
      <Item>
        <Link
          className={(navData) => (navData.isActive ? "active" : "")}
          aria-label={t("main.navigation.tradeBorrow")}
          to={lmaProduct}
          navbarscroll={navbarscroll.toString()}
        >
          {t("main.navigation.tradeBorrow")}
        </Link>
      </Item>
      <Item>
        <Link
          className={(navData) => (navData.isActive ? "active" : "")}
          to={cryptoMortgageProduct}
          aria-label={t("main.navigation.cryptoMortgage")}
          navbarscroll={navbarscroll.toString()}
        >
          {t("main.navigation.cryptoMortgage")}
        </Link>
      </Item>
      <Item>
        <Link
          className={(navData) => (navData.isActive ? "active" : "")}
          aria-label={t("main.navigation.contact")}
          to="/contact"
          navbarscroll={navbarscroll.toString()}
        >
          {t("main.navigation.contact")}
        </Link>
      </Item>
    </Links>
  );

  return (
    <Header
      data-testid="nav-element"
      isOpen={isOpen}
      navbarscroll={navbarscroll.toString()}
    >
      <HeaderContainer>
        <LogoContainer navbarscroll={navbarscroll.toString()}>
          {navbarscroll ? (
            <Logo logoVariant={light} className="logo-desktop" />
          ) : (
            <Logo logoVariant={dark} className="logo-desktop" />
          )}
          <Logo logoVariant={dark} className="logo-mobile" />

          <Label data-testid="nav-element-hamburger">
            <Input
              type="checkbox"
              onClick={() => setIsOpen(!isOpen)}
              onChange={() => setIsOpen(!isOpen)}
              checked={isOpen}
            />
            <Beam type={green} />
            <Beam type={green} />
            <Beam type={green} />
          </Label>
        </LogoContainer>
        {isSuccessLoginStatus || isSuccessGoogleStatus ? (
          <Container isOpen={isOpen} height="678px">
            <DesktopLinks>{linksNav}</DesktopLinks>
            <LoggedInLinks
              userName={nameUser}
              setIsOpen={setIsOpen}
              variant="main-nav"
              navbarscroll={navbarscroll}
            />
          </Container>
        ) : (
          <Container isOpen={isOpen} height="482px">
            {linksNav}
            <Buttons>
              <ButtonElement
                to={login}
                aria-label={t("main.navigation.login")}
                navbarscroll={navbarscroll.toString()}
              >
                {t("main.navigation.login")}
              </ButtonElement>
              {buttonOnClick ? (
                <Button
                  className="button-signup"
                  onClick={buttonOnClick}
                  name={t("main.navigation.signup")}
                  variant={ButtonVariant.signup}
                />
              ) : (
                <LinkButton
                  link={signupLink}
                  name={t("main.navigation.signup")}
                  variant={signup}
                />
              )}
            </Buttons>
          </Container>
        )}
      </HeaderContainer>
    </Header>
  );
};

export default Navigation;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { UploadStatus } from "../shared/enum";
import { GoogleProps, SignupGoogleProps, StateProps } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: SignupGoogleProps = {
  googleRegister: {
    access: "",
    refresh: "",
    user: "",
  },
  uploadGoogleStatus: UploadStatus.IDLE,
};

export const signupGoogleFetch = async (data: string) => {
  const state = data.split("state=")[1].split("&code=")[0];
  const code = data.split("state=")[1].split("&code=")[1].split("&scope")[0];

  const details = `state=${encodeURIComponent(state)}&code=${code}`;

  const res = await axios.post(`${API_URL}/auth/o/google-oauth2/`, details, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    withCredentials: true,
  });

  return res.data;
};

export const signupGoogle = createAsyncThunk(
  "signupGoogle",
  async (data: string): Promise<GoogleProps | null> => {
    const response = await signupGoogleFetch(data);
    return response;
  }
);

export const googleSignUpSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserGoogle() {
      return {
        googleRegister: {
          access: "",
          refresh: "",
          user: "",
        },
        uploadGoogleStatus: UploadStatus.REMOVE,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signupGoogle.pending, (state) => {
      return {
        ...state,
        uploadGoogleStatus: UploadStatus.LOADING,
        googleRegister: {
          access: "",
          refresh: "",
          user: "",
        },
      };
    });

    builder.addCase(
      signupGoogle.fulfilled,
      (state, action: PayloadAction<GoogleProps | null>) => {
        if (action.payload === null) {
          return state;
        }
        const uploadGoogleStatus =
          action.payload.user && action.payload.user !== ""
            ? UploadStatus.FOUND
            : UploadStatus.NONE_FOUND;

        return {
          ...state,
          googleRegister: action.payload,
          uploadGoogleStatus,
        };
      }
    );
  },
});

export const selectStateGoogleUser = (state: StateProps) => state.googleUser;

export const actions = {
  ...googleSignUpSlice.actions,
  signupGoogle,
};

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getRequest } from "../helpers/axios";
import { StateProps, UserDepositWalletsProps, WalletsProps } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: UserDepositWalletsProps = {
  wallets: [
    {
      address: "",
      network_id: "",
    },
  ],
};

export const userWalletsDepositFetch = async (
  token: string
): Promise<WalletsProps[] | null> => {
  const res = getRequest(`${API_URL}/finance/wallets`, token)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return initialState;
    });

  return res;
};

export const getUserWalletsDeposit = createAsyncThunk(
  "getUserWallet",
  async (token: string): Promise<WalletsProps[] | null> => {
    const response = await userWalletsDepositFetch(token);
    return response;
  }
);

export const userDepositWalletsSlice = createSlice({
  name: "userWallets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserWalletsDeposit.pending, (state) => {
      return {
        ...state,
      };
    });

    builder.addCase(
      getUserWalletsDeposit.fulfilled,
      (state, action: PayloadAction<WalletsProps[] | null>) => {
        if (action.payload === null) {
          return state;
        }
        const newState = { wallets: action.payload };

        return {
          ...newState,
        };
      }
    );
  },
});

export const selectStateUserWalletsDeposit = (state: StateProps) =>
  state.userDepositWallets;

export const actions = {
  getUserWalletsDeposit,
};

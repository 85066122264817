import styled from "styled-components";
import { Link } from "react-router-dom";

interface LoggedProps {
  variant?: "main-nav";
  scroll: string;
}

interface VariantProps {
  variant?: "main-nav";
}

interface LoadedProps {
  isLoaded: boolean;
}

export const LinksContainerLogged = styled.div<LoggedProps>`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font: ${({ theme }) => theme.typography.desktop.button};
  color: ${({ theme }) => theme.colors.violet};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    flex-direction: row;
    align-items: center;
    color: ${({ theme, variant, scroll }) => {
      if (variant && scroll !== "false") {
        return theme.colors.violet;
      }
      if (variant && scroll === "false") {
        return theme.colors.greenBright;
      }
      return theme.colors.violet;
    }};
  }

  svg {
    max-width: 24px;
    max-height: 24px;
    margin-bottom: 4px;
    display: none;

    path {
      fill: ${({ theme, variant, scroll }) => {
        if (variant && scroll !== "false") {
          return theme.colors.violet;
        }
        if (variant && scroll === "false") {
          return theme.colors.greenBright;
        }
        return theme.colors.violet;
      }};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
      display: block;
    }
  }
`;

export const LinkElementLogged = styled(Link)<VariantProps>`
  color: ${({ theme, variant }) =>
    theme.colors[variant ? "greenBright" : "primary"]};
  margin: 0 0 20px;
  display: "block";
  font: ${({ theme }) => theme.typography.desktop.value};
  text-transform: capitalize;

  &:hover {
    color: ${({ theme, variant }) =>
      theme.colors[variant ? "greenBright" : "primary"]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    display: block;
    margin: 0 0 15px;
    font: ${({ theme }) => theme.typography.desktop.button};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.cream};

    &:hover {
      color: ${({ theme }) => theme.colors.greenBright};
    }
  }
`;

export const NameContainerLogged = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  &:hover > div {
    opacity: 1;
    z-index: 2;
    height: auto;
    visibility: visible;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const NameLogged = styled(Link)<LoggedProps>`
  margin: 15px 0 20px;
  max-width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: ${({ theme, variant, scroll }) => {
      if (variant && scroll !== "false") {
        return theme.colors.violet;
      }
      if (variant && scroll === "false") {
        return theme.colors.greenBright;
      }
      return theme.colors.violet;
    }};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    margin: 0;
  }
`;

export const LinksLogged = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    opacity: 0;
    display: flex;
    visibility: hidden;
    z-index: -1;
    height: 0;
    position: absolute;
    top: 42px;
    right: 0;
    flex-direction: column;
    padding: 20px;
    width: 234px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: opacity 0.3s ease;
  }
`;

export const Break = styled.div<VariantProps>`
  width: 100%;
  height: ${({ variant }) => (variant ? "2px" : "1px")};
  margin-bottom: ${({ variant }) => (variant ? "30px" : "15px")};
  opacity: ${({ variant }) => (variant ? 1 : 0.3)};
  background-color: ${({ theme, variant }) =>
    theme.colors[variant ? "violet" : "cream"]};
  display: ${({ variant }) => (variant ? "block" : "none")};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    display: ${({ variant }) => (variant ? "none" : "block")};
  }
`;

export const SignOut = styled.p<VariantProps>`
  color: ${({ theme, variant }) =>
    theme.colors[variant ? "greenBright" : "primary"]};
  font: ${({ theme }) => theme.typography.desktop.value};
  text-transform: capitalize;
  margin: 20px 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme, variant }) =>
      theme.colors[variant ? "greenBright" : "primary"]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    margin: 0;
    font: ${({ theme }) => theme.typography.desktop.button};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.cream};

    &:hover {
      color: ${({ theme }) => theme.colors.greenBright};
    }
  }
`;

export const MobileLinks = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    display: none;
  }
`;

export const Namebox = styled.div<LoadedProps>`
  display: flex;
  justify-content: flex-start;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transition: opacity 0.3s ease;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    justify-content: center;
    align-items: center;
  }
`;

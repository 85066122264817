import { TokenVariant, UploadStatus } from "../shared/enum";
import { WhitelistedWallet } from "../components/ModalAddWallet/models";

export enum VerificationStatusType {
  verified = "verified",
  kycFailed = "kyc_failed",
  unsupportedState = "unsupported_residency",
}
export interface GoogleProps {
  access: string;
  refresh: string;
  user: string;
}

export interface SignupGoogleProps {
  googleRegister: GoogleProps;
  uploadGoogleStatus: UploadStatus;
}

export interface UserProps {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  re_password: string;
  prefix: string;
  phone: string;
  dataPrivacy: boolean;
  termsAndConditions: boolean;
  eSign: boolean;
}

export interface SignupProps {
  userInfoRegister: UserProps;
  uploadStatusRegister: UploadStatus;
}

export interface LoginProps {
  access: string;
  refresh: string;
}

export interface LoginUserProps {
  login: LoginProps;
  loginStatus: UploadStatus;
}

export interface UserInfoProps {
  email: string;
  first_name: string;
  last_name: string;
  kyc_verified: boolean;
  phone_number: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  active_link: string;
  latest_kyc_id: null | number | undefined;
  latest_loan_application_id: null | number;
  has_active_loan: boolean;
  status: UploadStatus;
  account_id: string;
  verification_status: VerificationStatusType;
}

export interface WalletsProps {
  address: string;
  network_id: string;
}

export interface WhitelistedWalletsResponse extends Pagination {
  results: WhitelistedWallet[];
}

export interface UserDepositWalletsProps {
  wallets: WalletsProps[];
}

export interface AssetsProps {
  type: TokenVariant;
  available_balance: string;
  available_balance_usdc: string;
}
export interface UserAsstesProps {
  assets: AssetsProps[];
  assets_usdc_value: string;
}

export interface UserLoanProps {
  amount: string;
  created: string;
  id: number;
  interest_rate: number;
  liquidation_threshold_value: number;
  loan_period: number;
  outbound_external_address?: string;
  start_date?: string;
  status: string;
  payoff_amount: string;
  user_whitelisted_wallet: WhitelistedWallet | null;
  instalments: {
    id: number;
    loan: number;
    due_date: string;
    amount: string;
    status: string;
  }[];
}

export interface LoanVarsProps {
  min_loan_amount: number;
  max_loan_amount: number;
  interest_rate: string;
  loan_to_deposit_ratio: string;
  min_loan_period_months: number;
  max_loan_period_months: number;
  status: UploadStatus;
}

export interface StateProps {
  login: LoginUserProps;
  user: SignupProps;
  googleUser: SignupGoogleProps;
  userInfo: UserInfoProps;
  userDepositWallets: UserDepositWalletsProps;
  whitelistedWallets: WhitelistedWalletsResponse;
  userAssets: UserAsstesProps;
  userLoans: UserLoanProps[];
  loanVars: LoanVarsProps;
}

export interface Pagination {
  count: number | null;
  next: number | null;
  previous: number | null;
}

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getRequest } from "../helpers/axios";
import { StateProps, UserAsstesProps } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: UserAsstesProps = {
  assets: [],
  assets_usdc_value: "",
};

export const userAssetsFetch = async (
  token: string
): Promise<UserAsstesProps | null> => {
  const res = getRequest(`${API_URL}/finance/assets`, token)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return initialState;
    });

  return res;
};

export const getUserAsstes = createAsyncThunk(
  "getUserAsstes",
  async (token: string): Promise<UserAsstesProps | null> => {
    const response = await userAssetsFetch(token);
    return response;
  }
);

export const userAssetsSlice = createSlice({
  name: "userAsstes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserAsstes.pending, (state) => {
      return {
        ...state,
      };
    });

    builder.addCase(
      getUserAsstes.fulfilled,
      (state, action: PayloadAction<UserAsstesProps | null>) => {
        if (action.payload === null) {
          return state;
        }

        return {
          ...action.payload,
        };
      }
    );
  },
});

export const selectStateUserAssets = (state: StateProps) => state.userAssets;
export const actions = {
  getUserAsstes,
};

import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { Homepage } from "./pages/Homepage";
import PrivateRoutes from "./utils/PrivateRoutes";
import RefreshToken from "./utils/RefreshToken";
import { links } from "./shared/links";
import { BoxSpinner } from "./components/BoxSpinner";
import { ScrollToTopPage } from "./components/ScrollToTopPage";
import "./App.css";
import { GlobalStyle } from "./components/GlobalStyle";

const LmaProduct = lazy(() => import("./pages/LmaProduct/LmaProduct"));
const CryptoMortageProduct = lazy(
  () => import("./pages/CryptoMortageProduct/CryptoMortageProduct")
);
const AccountActivated = lazy(
  () => import("./pages/AccountActivated/AccountActivated")
);
const TradePage = lazy(() => import("./pages/TradePage/TradePage"));
const Signup = lazy(() => import("./pages/Signup/Signup"));
const AccountInfoPage = lazy(
  () => import("./pages/AccountInfoPage/AccountInfoPage")
);
const Login = lazy(() => import("./pages/Login/Login"));
const Kyc = lazy(() => import("./pages/Kyc/Kyc"));
const BorrowPage = lazy(() => import("./pages/BorrowPage/BorrowPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage/NotFoundPage"));
const TermsOfUsePage = lazy(
  () => import("./pages/TermsOfUsePage/TermsOfUsePage")
);
const Kyw = lazy(() => import("./pages/Kyw/Kyw"));
const GlbaPage = lazy(() => import("./pages/GlbaPage/GlbaPage"));
const PressPage = lazy(() => import("./pages/PressPage/PressPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage/ContactUsPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage/DashboardPage"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const ResetPasswordPage = lazy(
  () => import("./pages/ResetPasswordPage/ResetPasswordPage")
);
const NewPasswordPage = lazy(
  () => import("./pages/NewPasswordPage/NewPasswordPage")
);
const EsignPage = lazy(() => import("./pages/EsignPage/EsignPage"));
const ListOfStatesPage = lazy(
  () => import("./pages/ListOfStatesPage/ListOfStatesPage")
);
const LoanPage = lazy(() => import("./pages/LoanPage/LoanPage"));
const WaitlistPage = lazy(() => import("./pages/WaitlistPage/WaitlistPage"));

const {
  homepage,
  // signup,
  accountActivated,
  lmaProduct,
  cryptoMortgageProduct,
  login,
  kyc,
  kyw,
  terms,
  resetPassword,
  newPassword,
  dashboard,
  borrow,
  accountInfo,
  trade,
  contact,
  privacy,
  dataPrivacy,
  press,
  esign,
  listOfStates,
  loanApplication,
  waitlist,
} = links;

const signupLink = "/sy1WiPcANVnqYusD";

const App = () => (
  <Router>
    <HelmetProvider>
      <RefreshToken />
      <GlobalStyle />
      <ScrollToTopPage />
      <Suspense fallback={<BoxSpinner routes />}>
        <Routes>
          <Route path={homepage} element={<Homepage />} />
          {/*    Replace signupLink with signup from links when testing phase will be done */}
          <Route path={signupLink} element={<Signup />} />
          <Route
            path={`${accountActivated}/:uid/:token`}
            element={<AccountActivated />}
          />
          <Route path={lmaProduct} element={<LmaProduct />} />
          <Route
            path={cryptoMortgageProduct}
            element={<CryptoMortageProduct />}
          />
          <Route path={login} element={<Login />} />
          <Route path={terms} element={<TermsOfUsePage />} />
          <Route path={resetPassword} element={<ResetPasswordPage />} />
          <Route path={contact} element={<ContactUsPage />} />
          <Route path={privacy} element={<PrivacyPolicy />} />
          <Route path={dataPrivacy} element={<GlbaPage />} />
          <Route
            path={`${newPassword}/:uid/:token`}
            element={<NewPasswordPage />}
          />
          <Route path={press} element={<PressPage />} />
          <Route path={esign} element={<EsignPage />} />
          <Route path={listOfStates} element={<ListOfStatesPage />} />
          <Route path={waitlist} element={<WaitlistPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path={dashboard} element={<DashboardPage />} />
            <Route path={borrow} element={<BorrowPage />} />
            <Route path={trade} element={<TradePage />} />
            <Route path={kyc} element={<Kyc />} />
            <Route path={kyw} element={<Kyw />} />
            <Route path={accountInfo} element={<AccountInfoPage />} />
            <Route path={loanApplication} element={<LoanPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </HelmetProvider>
  </Router>
);

export default App;

import React from "react";

import { Spinner } from "../Spinner";
import { Container } from "./BoxSpinner.styles";

interface BoxSpinnerProps {
  routes?: boolean;
}

const BoxSpinner = ({ routes }: BoxSpinnerProps): JSX.Element => (
  <Container routes={routes}>
    <Spinner />
  </Container>
);

export default BoxSpinner;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getRequest } from "../helpers/axios";
import { UploadStatus } from "../shared/enum";
import { links } from "../shared/links";
import { StateProps, UserInfoProps, VerificationStatusType } from "./models";

const { signup, dashboard } = links;

const API_URL = process.env.REACT_APP_API_URL;

const initialState: UserInfoProps = {
  email: "",
  first_name: "",
  last_name: "",
  kyc_verified: false,
  phone_number: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  zip_code: "",
  country: "",
  active_link: signup,
  latest_kyc_id: undefined,
  latest_loan_application_id: null,
  has_active_loan: false,
  status: UploadStatus.IDLE,
  account_id: "",
  verification_status: VerificationStatusType.kycFailed,
};

export const userInfoFetch = async (
  token: string
): Promise<UserInfoProps | null> => {
  const res = getRequest(`${API_URL}/auth/users/me`, token)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        email: "",
        first_name: "profile",
        last_name: "",
        kyc_verified: false,
        phone_number: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
        active_link: signup,
        latest_kyc_id: undefined,
        latest_loan_application_id: null,
        has_active_loan: false,
        status: UploadStatus.NONE_FOUND,
        verification_status: VerificationStatusType.kycFailed,
      };
    });

  return res;
};

export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (token: string): Promise<UserInfoProps | null> => {
    const response = await userInfoFetch(token);
    return response;
  }
);

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    resetUserInfo() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      return {
        ...state,
      };
    });

    builder.addCase(
      getUserInfo.fulfilled,
      (state, action: PayloadAction<UserInfoProps | null>) => {
        if (action.payload === null) {
          return state;
        }

        const userInfos = action.payload;

        userInfos.active_link = dashboard;
        userInfos.status = UploadStatus.FOUND;

        return {
          ...state,
          ...userInfos,
        };
      }
    );
  },
});

export const selectStateUserInfo = (state: StateProps) => state.userInfo;

export const actions = {
  ...userInfoSlice.actions,
  getUserInfo,
};

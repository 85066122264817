import { LoginProps } from "../features/models";
import { getLocalStorage } from "../helpers/storage";

export const Authorization = (): LoginProps | null => {
  const localTokens = getLocalStorage("moonmortgageLogin");
  const localTokensGoogle = getLocalStorage("moonmortgageLoginGoogle");

  const authLocalTokens: LoginProps | null = localTokens || null;

  const authSessionTokensGoogle = localTokensGoogle || null;

  const checkAuth: LoginProps | null =
    authLocalTokens || authSessionTokensGoogle;

  return checkAuth;
};

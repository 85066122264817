import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getRequest } from "../helpers/axios";
import { StateProps, UserLoanProps } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: UserLoanProps[] = [
  {
    amount: "",
    created: "",
    id: 0,
    interest_rate: 0,
    liquidation_threshold_value: 0,
    loan_period: 0,
    outbound_external_address: "",
    start_date: "",
    status: "",
    payoff_amount: "",
    user_whitelisted_wallet: null,
    instalments: [],
  },
];

export const userLoansFetch = async (
  token: string
): Promise<UserLoanProps[] | null> => {
  const res = getRequest(`${API_URL}/trade_and_borrow_loan/`, token)
    .then((response) => {
      return response.data.results;
    })
    .catch(() => {
      return initialState;
    });

  return res;
};

export const getUserLoans = createAsyncThunk(
  "getUserLoans",
  async (token: string): Promise<UserLoanProps[] | null> => {
    const response = await userLoansFetch(token);
    return response;
  }
);

export const userLoansSlice = createSlice({
  name: "userLoans",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserLoans.pending, (state) => {
      return state;
    });

    builder.addCase(
      getUserLoans.fulfilled,
      (state, action: PayloadAction<UserLoanProps[] | null>) => {
        if (action.payload === null) {
          return state;
        }
        return action.payload;
      }
    );
  },
});

export const selectStateUserLoans = (state: StateProps) => state.userLoans;

export const actions = {
  getUserLoans,
};

import React, { useEffect, useRef } from "react";
import { useNetworkStatus } from "react-adaptive-hooks";

import banner from "../../assets/mm_blog_banner.png";
import { LinkVariant } from "../LinkButton/LinkButton.styles";
import { LinkButton } from "../LinkButton";
import { LandingVariant } from "../../shared/enum";
import { Framer } from "../Framer";
import { FramerVariant } from "../Framer/Framer";
import {
  Section,
  Container,
  Video,
  Info,
  Title,
  SubTitle,
  ButtonContainer,
  ScrollTo,
  Image,
} from "./HeroBanner.styles";
import { Button } from "../Button";
import { ButtonVariant } from "../Button/Button.styles";

interface HeroBannerProps {
  variant: LandingVariant;
  animation: string;
  title: string;
  subtitle: string;
  buttonNameMain?: string;
  buttonUrlMain?: string;
  buttonOnClickMain?: () => void;
  buttonNameSecondary?: string;
  buttonUrlSecondary?: string;
}

const HeroBanner = ({
  variant = LandingVariant.main,
  animation,
  title,
  subtitle,
  buttonNameMain,
  buttonUrlMain,
  buttonOnClickMain,
  buttonNameSecondary,
  buttonUrlSecondary,
}: HeroBannerProps): JSX.Element => {
  const { main } = LinkVariant;
  const { main: mainFramer, opacity, long } = FramerVariant;

  const videoRef = useRef<HTMLVideoElement>(null);

  // Fix mobile safari autoplay issue
  useEffect(() => {
    const { current: video } = videoRef;
    if (video) {
      video.muted = true;
    }
  }, []);

  const { effectiveConnectionType } = useNetworkStatus();

  const renderBanner = () => {
    switch (effectiveConnectionType) {
      case "slow-2g":
      case "2g":
        return <Image src={banner} alt="crypto-banner" />;
      default:
        return (
          <Video
            autoPlay
            loop
            controls={false}
            playsInline
            ref={videoRef}
            data-testid="hero-banner-video-element"
          >
            <source src={animation} type="video/mp4" />
          </Video>
        );
    }
  };

  return (
    <Section data-testid="hero-banner-element">
      <Container>
        <Framer variant={opacity}>{renderBanner()}</Framer>
        <Info>
          <Framer variant={mainFramer}>
            <Title variant={variant}>{title}</Title>
          </Framer>
          <Framer variant={long}>
            <SubTitle variant={variant}>{subtitle}</SubTitle>
          </Framer>
          <Framer variant={opacity}>
            <ButtonContainer variant={variant}>
              {buttonUrlMain && buttonNameMain && (
                <LinkButton
                  link={buttonUrlMain}
                  name={buttonNameMain}
                  variant={main}
                />
              )}
              {buttonNameMain && buttonOnClickMain && (
                <Button
                  onClick={buttonOnClickMain}
                  name={buttonNameMain}
                  variant={ButtonVariant.main}
                />
              )}
              {buttonUrlSecondary && buttonNameSecondary && (
                <ScrollTo
                  spy
                  smooth
                  to={buttonUrlSecondary}
                  aria-label={buttonNameSecondary}
                >
                  <span>{buttonNameSecondary}</span>
                </ScrollTo>
              )}
            </ButtonContainer>
          </Framer>
        </Info>
      </Container>
    </Section>
  );
};

export default HeroBanner;

import { decodedToken, encodedToken } from "./cryptoValue";

export const setLocalStorage = (
  name: string,
  val: { access: string; refresh: string },
  remember: string
) => {
  const date = new Date();
  const variant =
    remember === "remembered" ? 24 * 60 * 60 * 1000 : 60 * 60 * 1000;
  const expiryDate = date.setTime(date.getTime() + variant);

  const item = {
    access: encodedToken(val.access),
    refresh: encodedToken(val.refresh),
    expiry: new Date(expiryDate),
  };
  localStorage.setItem(name, JSON.stringify(item));
};

export const setLocalStorageRemember = (name: string, value: string) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getLocalStorage = (
  name: string
): { access: string; refresh: string } | null => {
  const values = localStorage.getItem(name);

  if (!values) {
    return null;
  }

  const item = JSON.parse(values);

  if (new Date(item.expiry) < new Date()) {
    localStorage.removeItem(name);
    return null;
  }

  return {
    access: decodedToken(item.access),
    refresh: decodedToken(item.refresh),
  };
};

export const deleteLocalStorage = (name: string) => {
  localStorage.removeItem(name);
};

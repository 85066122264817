import React, { ReactChild } from "react";
import { ThemeProvider as OThemeProvider } from "styled-components";

const baseTheme = {
  colors: {
    primary: "#003C32",
    white: "#fff",
    cream: "#EEEEE7",
    violet: "#8C7DFF",
    black: "#1C1C1C",
    greenLight: "#C3DDD4",
    greenBright: "#00F78C",
    greenDark: "#0E2326",
    grey: "#949d9a",
    greyGreen: "#7F9D93",
    disabledGrey: "#3a6c63",
    disabled: "#fcfcfc",
    warning: "#E03A44",
  },
  fontFamily: { interFont: "Inter, sans-serif" },
  typography: {
    desktop: {
      headingXXXL: "normal normal 600 140px/140px Neue Machina, sans-serif",
      headingXXL: "normal normal 600 100px/110px Neue Machina, sans-serif",
      headingXL: "normal normal 500 100px/100px Neue Machina, sans-serif",
      headingL: "normal normal 600 80px/80px Neue Machina, sans-serif",
      headingM: "normal normal 500 60px/72px Neue Machina, sans-serif",
      headingBoldM: "normal normal 600 60px/72px Neue Machina, sans-serif",
      headingS: "normal normal 500 48px/60px Neue Machina, sans-serif",
      headingXS: "normal normal 500 44px/56px Neue Machina, sans-serif",

      subheadingXXL: "normal normal 500 32px/58px Neue Machina, sans-serif",
      subheadingXL: "normal normal 500 32px/36.8px Neue Machina, sans-serif",
      subheadingL: "normal normal 500 18px/27px Neue Machina, sans-serif",
      subheadingM: "normal normal 400 16px/19.2px Neue Machina, sans-serif",
      subheadingS: "normal normal 500 14px/14px Neue Machina, sans-serif",
      subheadingBoldS: "normal normal 700 14px/16px Neue Machina, sans-serif",

      paragraphXXXL: "normal normal 500 26px/36.4px Neue Machina, sans-serif",
      paragraphXXL: "normal normal 500 18px/32px Neue Machina, sans-serif",
      paragraphXXLShort: "normal normal 500 18px/27px Neue Machina, sans-serif",
      paragraphXL: "normal normal 500 16px/24px Neue Machina, sans-serif",
      paragraphyL: "normal normal 500 14px/17px Neue Machina, sans-serif",
      paragraphyM: "normal normal 500 12px/24px Neue Machina, sans-serif",
      paragraphS: "normal normal 500 10px/10px Neue Machina, sans-serif",

      link: "normal normal 700 14px/15px Neue Machina, sans-serif",
      description: "normal normal 500 14px/20px Neue Machina, sans-serif",

      button: "normal normal 700 14px/14px Neue Machina, sans-serif",
      input: "normal normal 400 46px/55.2px Neue Machina, sans-serif",
      value: "normal normal 600 24px/28.8px Neue Machina, sans-serif",
    },

    mobile: {
      headingXXXL: "normal normal 600 70px/70px Neue Machina, sans-serif",
      headingXXL: "normal normal 600 60px/60px Neue Machina, sans-serif",
      headingXL: "normal normal 600 50px/55px Neue Machina, sans-serif",
      headingL: "normal normal 600 42px/46px Neue Machina, sans-serif",
      headingM: "normal normal 600 40px/44px Neue Machina, sans-serif",
      headingS: "normal normal 600 36px/42px Neue Machina, sans-serif",
      headingXS: "normal normal 600 30px/36px Neue Machina, sans-serif",
      headingXXS: "normal normal 600 24px/30px Neue Machina, sans-serif",

      subheadingL: "normal normal 500 20px/36px Neue Machina, sans-serif",
      subheadingM: "normal normal 500 18px/26px Neue Machina, sans-serif",

      paragraphyL: "normal normal 500 20px/24px Neue Machina, sans-serif",
      paragraphyM: "normal normal 500 14px/16px Neue Machina, sans-serif",
      paragraphyS: "normal normal 500 13px/16px Neue Machina, sans-serif",
      paragraphyXS: "normal normal 700 12px/12px Neue Machina, sans-serif",
      paragraphyXXS: "normal normal 700 10px/10px Neue Machina, sans-serif",

      linkM: "normal normal 500 32px/36px Neue Machina, sans-serif",
      linkS: "normal normal 500 22px/28px Neue Machina, sans-serif",
      input: "normal normal 400 32px/38.4px Neue Machina, sans-serif",
    },
  },
  breakpoints: {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tabletS: "575px",
    tablet: "768px",
    desktopS: "1024px",
    desktopM: "1440px",
    desktopL: "2000px",
  },
};

export type ColorsType = typeof baseTheme["colors"];
export type TypographyType = typeof baseTheme["typography"];
export type ThemeType = typeof baseTheme;
export type ThemeProps = { theme: ThemeType };
export interface Props {
  children: ReactChild;
  theme?: ThemeType;
}

const ThemeProvider = ({ children, theme = baseTheme }: Props): JSX.Element => (
  <OThemeProvider theme={theme}>{children}</OThemeProvider>
);

export default ThemeProvider;

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { userSlice } from "../features/UserSlice";
import { googleSignUpSlice } from "../features/GoogleSignUpSlice";
import { loginSlice } from "../features/LoginSlice";
import { userInfoSlice } from "../features/UserInfoSlice";
import { userDepositWalletsSlice } from "../features/UserDepositWalletsSlice";
import { userAssetsSlice } from "../features/UserAssetsSlice";
import { userLoansSlice } from "../features/UserLoansSlice";
import { whitelistedWalletsSlice } from "../features/WhitelistedWalletsSlice";
import { loanVarsSlice } from "../features/LoanVarsSlice";

const rootReducer = combineReducers({
  login: loginSlice.reducer,
  user: userSlice.reducer,
  googleUser: googleSignUpSlice.reducer,
  userInfo: userInfoSlice.reducer,
  userDepositWallets: userDepositWalletsSlice.reducer,
  whitelistedWallets: whitelistedWalletsSlice.reducer,
  userAssets: userAssetsSlice.reducer,
  userLoans: userLoansSlice.reducer,
  loanVars: loanVarsSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

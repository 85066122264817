import React from "react";
import { Helmet } from "react-helmet-async";

interface HeadProps {
  name: string;
  link?: string;
}

const Head = ({ name, link }: HeadProps) => (
  <Helmet>
    <title>{name}</title>
    {link && <link rel="canonical" href={link} />}
  </Helmet>
);

export default Head;

import styled from "styled-components";

export enum HamburgerVariant {
  black = "black",
  green = "green",
}

interface HeaderProps {
  isOpen: boolean;
  height: string;
  navbarscroll?: string;
}

interface HeaderScrollProps {
  navbarscroll?: string;
}

interface HamburgerProps {
  type: HamburgerVariant;
}

export const HeaderContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoContainer = styled.div<HeaderScrollProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 12;
  padding: 25px 20px;
  background: ${({ theme }) => theme.colors.primary};
  transition: background-color 0.3s ease;

  img {
    max-width: 150px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    max-width: 160px;
    padding: 0;
    background: ${({ theme, navbarscroll }) =>
      navbarscroll === "true" ? theme.colors.cream : theme.colors.primary};
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 30px;
  cursor: pointer;

  span:nth-of-type(1) {
    width: 60%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    display: none;
  }
`;

export const Input = styled.input`
  display: none;

  &:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    width: 50%;
    transform: rotatez(45deg) translate(8px, 4px);
  }

  &:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  &:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(13px, -9px) rotatez(45deg);
  }
`;

export const Beam = styled.span<HamburgerProps>`
  background: ${({ theme, type }) =>
    type === HamburgerVariant.black
      ? theme.colors.black
      : theme.colors.greenBright};
  height: 2px;
  margin: 6px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
`;

export const Container = styled.nav<HeaderProps>`
  position: absolute;
  top: ${({ isOpen }) => (isOpen ? "50px" : "-150vh")};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 0 20px;
  transition: opacity 0.3s ease, top 0.3s ease;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  z-index: ${({ isOpen }) => (isOpen ? "8" : "-1")};
  height: ${({ height }) => height};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    position: relative;
    top: 0;
    opacity: 1;
    height: auto;
    margin-top: 0;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 94%;
    margin-left: 20px;
    z-index: 8;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopM}) {
    margin-left: 180px;
  }
`;

export const ContainerLogged = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    justify-content: flex-end;
  }
`;

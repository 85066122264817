export const encodedToken = (token: string) =>
  new TextEncoder()
    .encode(token)
    .toString()
    .split(",")
    .map((item) => parseInt(item, 10).toString(16))
    .join(",");

export const decodedToken = (token: string) =>
  new TextDecoder("utf-8").decode(
    new Uint8Array(token.split(",").map((item) => parseInt(item, 16)))
  );

import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export enum LinkVariant {
  main = "main",
  secondary = "secondary",
  form = "form",
  signup = "signup",
}
interface LinkProps {
  variant: LinkVariant;
}

export const LinkElement = styled(Link)<LinkProps>`
  font: ${({ theme }) => theme.typography.desktop.button};
  border: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 80px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.violet};
    border-color: ${({ theme }) => theme.colors.violet};
  }

  ${({ variant, theme }) => {
    const mainVariant = css`
      display: inline-block;
      color: ${theme.colors.primary};
      background: ${theme.colors.greenBright};
      padding: 22px 15px 19px 15px;
      height: 55px;

      @media (min-width: ${theme.breakpoints.mobileM}) {
        padding: 22px 20px 19px 20px;
      }

      @media (min-width: ${theme.breakpoints.mobileL}) {
        padding: 22px 40px 19px 40px;
      }
    `;

    const secondaryVariant = css`
      color: ${theme.colors.cream};
      background: transparent;
      border: 1px solid ${theme.colors.cream};
      padding: 22px 15px 19px 15px;
      height: 55px;

      @media (min-width: ${theme.breakpoints.mobileM}) {
        padding: 22px 20px 19px 20px;
      }

      @media (min-width: ${theme.breakpoints.mobileL}) {
        padding: 22px 40px 19px 40px;
      }
    `;

    const formVariant = css`
      display: inline-block;
      border-radius: 30px;
      padding: 16px 30px 14px;
      border: 2px solid ${theme.colors.violet};
      color: ${theme.colors.violet};
      background: ${theme.colors.white};
      height: 44px;

      &:hover {
        color: ${theme.colors.white};
        background: ${theme.colors.violet};
      }

      @media (min-width: ${theme.breakpoints.mobileL}) {
        padding: 16px 125px 14px;
      }
    `;

    const signupVariant = css`
      border-radius: 30px;
      padding: 22px 40px 19px;
      color: ${theme.colors.primary};
      background: ${theme.colors.greenBright};
      height: 55px;

      &:hover {
        color: ${theme.colors.white};
        background: ${theme.colors.violet};
      }

      @media (min-width: ${theme.breakpoints.desktopS}) {
        padding: 9px 15px 5px;
        height: 28px;
      }
    `;

    switch (variant) {
      case LinkVariant.main:
        return mainVariant;
      case LinkVariant.secondary:
        return secondaryVariant;
      case LinkVariant.form:
        return formVariant;
      case LinkVariant.signup:
        return signupVariant;
      default:
        return "color: #000; background: transparent";
    }
  }}
`;

export const Name = styled.span`
  display: block;
`;

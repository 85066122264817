import React, { lazy, Suspense } from "react";

import { Navigation } from "../Navigation";

const Footer = lazy(() => import("../Footer/Footer"));

type Props = {
  children: React.ReactNode;
  buttonOnClick?: () => void;
};

const Layout = ({ children, buttonOnClick }: Props): JSX.Element => (
  <>
    <Navigation buttonOnClick={buttonOnClick} />
    {children}
    <Suspense fallback={null}>
      <Footer />
    </Suspense>
  </>
);

export default Layout;

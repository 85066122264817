import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { StateProps, LoanVarsProps } from "./models";
import { UploadStatus } from "../shared/enum";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: LoanVarsProps = {
  min_loan_amount: 6001,
  max_loan_amount: 1000000,
  interest_rate: "0.12",
  loan_to_deposit_ratio: "0.4",
  min_loan_period_months: 1,
  max_loan_period_months: 6,
  status: UploadStatus.IDLE,
};

export const loanVarsFetch = async (): Promise<LoanVarsProps | null> => {
  const res = await axios
    .get(`${API_URL}/trade_and_borrow_loan/vars`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return initialState;
    });

  return res;
};

export const getLoanVars = createAsyncThunk(
  "getLoanVars",
  async (): Promise<LoanVarsProps | null> => {
    const response = await loanVarsFetch();
    return response;
  }
);

export const loanVarsSlice = createSlice({
  name: "loanVars",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLoanVars.pending, (state) => {
      return {
        ...state,
      };
    });

    builder.addCase(
      getLoanVars.fulfilled,
      (state, action: PayloadAction<LoanVarsProps | null>) => {
        if (action.payload === null) {
          return state;
        }

        return { ...action.payload, status: UploadStatus.FOUND };
      }
    );
  },
});

export const selectStateLoanVars = (state: StateProps) => state.loanVars;
export const actions = {
  getLoanVars,
};

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { scrollToTop } from "../../helpers/scrollToTop";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    scrollToTop();

    return () => {};
  }, [location]);

  return null;
};

export default ScrollToTop;

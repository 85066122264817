import React from "react";

import { LinkElement, LinkVariant, Name } from "./LinkButton.styles";

interface LinkProps {
  name: string;
  variant: LinkVariant;
  link: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const Link = ({
  name,
  link,
  variant,
  style,
  disabled,
}: LinkProps): JSX.Element => (
  <LinkElement
    to={disabled ? "/" : link}
    aria-label={name}
    variant={variant}
    className="link-button"
    style={style}
  >
    <Name>{name}</Name>
  </LinkElement>
);

export default Link;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { links } from "../shared/links";
import { UploadStatus } from "../shared/enum";
import { selectStateLoginUser } from "../features/LoginSlice";
import { selectStateGoogleUser } from "../features/GoogleSignUpSlice";
import { Authorization } from "./Authorization";

const { login } = links;

const PrivateRoutes = (): JSX.Element => {
  const { FOUND } = UploadStatus;
  const loginState = useSelector(selectStateLoginUser);
  const googleUserState = useSelector(selectStateGoogleUser);
  const isSuccessLoginStatus = loginState?.loginStatus === FOUND;
  const isSuccessGoogleStatus = googleUserState?.uploadGoogleStatus === FOUND;
  const storage = Authorization();
  const authorization =
    isSuccessLoginStatus || isSuccessGoogleStatus || storage;

  return authorization ? <Outlet /> : <Navigate to={login} />;
};

export default PrivateRoutes;

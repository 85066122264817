import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { postRequest } from "../helpers/axios";
import { UploadStatus } from "../shared/enum";
import { LoginProps, LoginUserProps, StateProps } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

interface DataProps {
  email: string;
  password: string;
}

const initialState: LoginUserProps = {
  login: {
    access: "",
    refresh: "",
  },
  loginStatus: UploadStatus.IDLE,
};

export const loginUserFetch = async (data: DataProps) => {
  const res = await postRequest(`${API_URL}/auth/jwt/create/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });

  return res;
};

export const loginUser = createAsyncThunk(
  "loginUser",
  async (data: DataProps): Promise<LoginProps | null> => {
    const response = await loginUserFetch(data);
    return response;
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<LoginProps>) {
      state.login = action.payload;
      state.loginStatus = UploadStatus.FOUND;
    },
    resetUser() {
      return {
        login: {
          access: "",
          refresh: "",
        },
        loginStatus: UploadStatus.REMOVE,
      };
    },
    clearUser() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      return {
        ...state,
        loginStatus: UploadStatus.LOADING,
        login: {
          access: "",
          refresh: "",
        },
      };
    });

    builder.addCase(
      loginUser.fulfilled,
      (state, action: PayloadAction<LoginProps | null>) => {
        if (action.payload === null) {
          return state;
        }
        const loginStatus =
          action.payload.access && action.payload.access !== ""
            ? UploadStatus.FOUND
            : UploadStatus.NONE_FOUND;

        return {
          ...state,
          login: action.payload,
          loginStatus,
        };
      }
    );
  },
});

export const selectStateLoginUser = (state: StateProps) => state.login;

export const actions = {
  ...loginSlice.actions,
  loginUser,
};

import styled, { css } from "styled-components";

export enum ButtonVariant {
  main = "main",
  secondary = "secondary",
  modal = "modal",
  borrow = "borrow",
  final = "final",
  remove = "remove",
  borrowGray = "borrowGray",
  signup = "signup",
}

interface ButtonProps {
  variant: ButtonVariant;
  spinner?: boolean;
  horizontalPosition?: "right";
}

export const Button = styled.button<ButtonProps>`
  font: ${({ theme }) => theme.typography.desktop.button};
  border: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 80px;
  text-transform: uppercase;
  margin: ${({ horizontalPosition }) =>
    horizontalPosition &&
    (horizontalPosition === "right" ? "0 0 0 auto" : "0")};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.violet};
    border-color: ${({ theme }) => theme.colors.violet};
  }

  &:disabled {
    opacity: ${({ spinner }) => (spinner ? "1" : "0.3")};
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme, spinner }) =>
      spinner ? theme.colors.disabledGrey : theme.colors.greenLight};
    border-color: ${({ theme, spinner }) =>
      spinner ? theme.colors.disabledGrey : theme.colors.greenLight};
  }

  text-transform: uppercase;

  ${({ variant, theme }) => {
    const mainVariant = css`
      color: ${theme.colors.primary};
      background: ${theme.colors.greenBright};
      padding: 22px 10px 19px 10px;
      height: 55px;
    `;

    const secondaryVariant = css`
      color: ${theme.colors.cream};
      background: transparent;
      border: 1px solid ${theme.colors.cream};
      height: 55px;
      padding: 16px 10px 12px 10px;

      @media (min-width: ${theme.breakpoints.mobileM}) {
        padding: 16px 40px 12px 40px;
      }
    `;

    const modalVariant = css`
      width: 100%;
      color: ${theme.colors.cream};
      background: ${theme.colors.violet};
      border: 2px solid ${theme.colors.violet};
      height: 44px;
      padding: 15px 40px 11px 40px;

      &:hover {
        color: ${theme.colors.violet};
        background-color: ${theme.colors.white};
      }

      &:disabled {
        opacity: 0.35;
        background-color: ${theme.colors.greenDark};
        border-color: ${theme.colors.greenDark};
        color: ${theme.colors.white};

        &:hover {
          opacity: 0.35;
          background-color: ${theme.colors.greenDark};
          border-color: ${theme.colors.greenDark};
          color: ${theme.colors.white};
        }
      }
    `;

    const borrowVariant = css`
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.colors.violet};
      background: ${theme.colors.white};
      border: 2px solid ${theme.colors.violet};
      height: 44px;
      padding: 16px 30px 14px;

      span {
        margin-top: 2px;
      }

      path {
        width: 14px;
        height: 14px;
      }

      &:hover {
        color: ${theme.colors.white};
        background-color: ${theme.colors.violet};

        path {
          stroke: ${theme.colors.white};
          transition: stroke 0.3s ease;
        }
      }

      &:disabled {
        opacity: 0.5;
        color: ${theme.colors.violet};
        background: ${theme.colors.white};
        border: 2px solid ${theme.colors.violet};

        path {
          stroke: ${theme.colors.violet};
        }

        &:hover {
          color: ${theme.colors.violet};
          background: ${theme.colors.white};
          border: 2px solid ${theme.colors.violet};

          path {
            stroke: ${theme.colors.violet};
          }
        }
      }

      @media (min-width: ${theme.breakpoints.mobileM}) {
        padding: 16px 38px 14px;
      }
    `;

    const borrowGrayVariant = css`
      ${borrowVariant}
      border-color:${theme.colors.grey};
      color: ${theme.colors.grey};

      &:hover {
        color: ${theme.colors.cream};
        background: ${theme.colors.grey};
        border: 2px solid ${theme.colors.grey};

        path {
          stroke: ${theme.colors.grey};
        }
      }
    `;

    const finalVariant = css`
      color: ${theme.colors.cream};
      background: ${theme.colors.violet};
      border: 2px solid ${theme.colors.violet};
      height: 44px;
      padding: 15px 40px 11px 40px;

      &:hover {
        color: ${theme.colors.violet};
        background-color: ${theme.colors.white};
      }
    `;

    const removeVariant = css`
      color: ${theme.colors.cream};
      background: ${theme.colors.warning};
      border: 2px solid ${theme.colors.warning};
      height: 44px;
      padding: 15px 40px 11px 40px;

      &:hover {
        color: ${theme.colors.warning};
        border-color: ${theme.colors.warning};
        background-color: ${theme.colors.white};
      }
    `;
    const signupVariant = css`
      border-radius: 30px;
      padding: 22px 40px 19px;
      color: ${theme.colors.primary};
      background: ${theme.colors.greenBright};
      height: 55px;

      &:hover {
        color: ${theme.colors.white};
        background: ${theme.colors.violet};
      }

      @media (min-width: ${theme.breakpoints.desktopS}) {
        padding: 9px 15px 5px;
        height: 28px;
      }
    `;

    switch (variant) {
      case ButtonVariant.main:
        return mainVariant;
      case ButtonVariant.secondary:
        return secondaryVariant;
      case ButtonVariant.modal:
        return modalVariant;
      case ButtonVariant.borrow:
        return borrowVariant;
      case ButtonVariant.borrowGray:
        return borrowGrayVariant;
      case ButtonVariant.final:
        return finalVariant;
      case ButtonVariant.remove:
        return removeVariant;
      case ButtonVariant.signup:
        return signupVariant;
      default:
        return "color: #000; background: transparent";
    }
  }}
`;

import React from "react";
import { createGlobalStyle } from "styled-components";

import { ThemeType } from "../ThemeProvider";

export interface IGlobalStylesProps {
  theme: ThemeType;
}

const GlobalStyleComponent = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    background: ${({ theme }: IGlobalStylesProps) => theme.colors.primary};
    font-family: "Neue Machina", "Helvetica Neue", sans-serif;
    font-feature-settings: 'liga' off;
  }

    body,
  html,
  div,
  blockquote,
  img,
  label,
  button,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  form,
  a,
  fieldset,
  input,
  th,
  td {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    letter-spacing: 0.1px;

    &:focus:not(:focus-visible){
      outline: none
    }
    
    &:focus-visible{
      outline: 2px solid ${({ theme }: IGlobalStylesProps) =>
        theme.colors.violet};;
      outline-offset: 2px;
    }
  }

    h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }: IGlobalStylesProps) => theme.colors.primary};
  }

  ul,
  ol {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button:not(:disabled),
  [role="button"]:not(:disabled) {
    cursor: pointer;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: ${({ theme }) => theme.colors.cream};
  }
  .ant-select-dropdown {
    border-radius: 4px;
    width: 100%;
    padding: 5px;
    box-shadow: 4px 40px 16px rgba(0, 0, 0, 0.01), 2px 23px 14px rgba(0, 0, 0, 0.04), 1px 10px 10px rgba(0, 0, 0, 0.07), 0px 3px 6px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08);
  }
  .ant-select-selection-item {
    margin-left: 9px;
    bottom: 4px;
  }
  .ant-select-selector {
    left: -5px;
    bottom: -4px;
  }
  .ant-select-item {
    border-radius: 4px;
    padding: 10px;
  }
  .ant-select-disabled  {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1; 
  }
`;

const GlobalStyle = (): JSX.Element => <GlobalStyleComponent />;

export default GlobalStyle;

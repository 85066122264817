import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { IntercomProvider } from "react-use-intercom";

import App from "./App";
import { store } from "./app/store";
import { ThemeProvider } from "./components/ThemeProvider";

import "./i18n";
import "./index.css";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} initializeDelay={2000}>
          <App />
        </IntercomProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

import styled from "styled-components";
import { NavLink, Link as ButtonLink } from "react-router-dom";

interface HeaderProps {
  isOpen: boolean;
  navbarscroll?: string;
}

interface HeaderScrollProps {
  navbarscroll?: string;
}

export const Header = styled.header<HeaderProps>`
  position: fixed;
  top: 0;
  width: 100%;
  transition: height 0.3s ease, background-color 0.3s ease;
  height: ${({ isOpen }) => (isOpen ? "101vh" : "98px")};
  overflow-x: ${({ isOpen }) => (isOpen ? "hidden" : "visible")};
  overflow-y: ${({ isOpen }) => (isOpen ? "scroll" : "visible")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
  background: ${({ theme }) => theme.colors.primary};

  .logo-desktop {
    display: none;
  }

  .button-signup {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    padding: 25px 50px;
    overflow-y: visible;
    height: auto;
    background: ${({ theme, navbarscroll }) =>
      navbarscroll === "true" ? theme.colors.cream : theme.colors.primary};

    .button-signup {
      display: block;
    }

    .link-button,
    .button-signup {
      transition: color 0.3s ease, background-color 0.3s ease;
      background: ${({ theme, navbarscroll }) =>
        navbarscroll === "true"
          ? theme.colors.violet
          : theme.colors.greenBright};
      color: ${({ theme, navbarscroll }) =>
        navbarscroll === "true" ? theme.colors.cream : theme.colors.primary};

      &:hover {
        background: ${({ theme, navbarscroll }) =>
          navbarscroll === "true"
            ? theme.colors.greenBright
            : theme.colors.violet};
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    .logo-desktop {
      display: block;
    }

    .logo-mobile {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopM}) {
    padding: 25px 75px;
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoContainer = styled.div<HeaderScrollProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 12;
  padding: 25px 20px;
  background: ${({ theme }) => theme.colors.primary};
  transition: background-color 0.3s ease;

  img {
    max-width: 150px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    max-width: 160px;
    padding: 0;
    background: ${({ theme, navbarscroll }) =>
      navbarscroll === "true" ? theme.colors.cream : theme.colors.primary};
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 30px;
  cursor: pointer;

  span:nth-of-type(1) {
    width: 60%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    display: none;
  }
`;

export const Input = styled.input`
  display: none;

  &:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    width: 50%;
    transform: rotatez(45deg) translate(8px, 4px);
  }

  &:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  &:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(13px, -9px) rotatez(45deg);
  }
`;

export const Beam = styled.span`
  background: ${({ theme }) => theme.colors.greenBright};
  height: 2px;
  margin: 6px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
`;

export const Links = styled.ul`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    flex-direction: row;
  }
`;

export const Item = styled.li`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.greenBright};
  padding: 5px 10px 5px 0;

  font: ${({ theme }) => theme.typography.desktop.value};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    margin: 0 5px;
    padding: 5px 10px;
    font: ${({ theme }) => theme.typography.desktop.subheadingBoldS};
  }
`;

export const Link = styled(NavLink)<HeaderScrollProps>`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.greenBright};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.greenBright};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    text-transform: uppercase;

    color: ${({ theme, navbarscroll }) =>
      navbarscroll === "true"
        ? theme.colors.primary
        : theme.colors.greenBright};

    &:hover {
      color: ${({ theme, navbarscroll }) =>
        navbarscroll === "true"
          ? theme.colors.primary
          : theme.colors.greenBright};
    }

    &.active,
    &.active:hover {
      color: ${({ theme }) => theme.colors.violet};
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin: 100px 0 40px;
  animation: 0.6s fadeIn;
  animation-fill-mode: forwards;
  opacity: 0;
  visibility: hidden;

  @keyframes fadeIn {
    90% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    margin: 0;
  }
`;

export const ButtonElement = styled(ButtonLink)<HeaderScrollProps>`
  padding: 10px 0;
  margin-right: 35px;
  font: ${({ theme }) => theme.typography.mobile.link};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.greenBright};

  &:hover {
    color: ${({ theme }) => theme.colors.greenBright};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mobileM}) {
    font: ${({ theme }) => theme.typography.mobile.linkM};
    font-weight: 600;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    text-transform: uppercase;
    font: ${({ theme }) => theme.typography.desktop.subheadingBoldS};

    color: ${({ theme, navbarscroll }) =>
      navbarscroll === "true" ? theme.colors.violet : theme.colors.greenBright};

    &:hover {
      color: ${({ theme, navbarscroll }) =>
        navbarscroll === "true"
          ? theme.colors.violet
          : theme.colors.greenBright};
    }
  }
`;

export const DesktopLinks = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    display: flex;
  }
`;

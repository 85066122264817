import styled from "styled-components";
import { Link } from "react-scroll";

import { LandingVariant } from "../../shared/enum";

interface TitleProps {
  variant?: LandingVariant;
}
interface SubtitleProps {
  variant?: LandingVariant;
}
interface ButtonContainerProps {
  variant?: LandingVariant;
}

export const Section = styled.section`
  margin-top: 98px;
  background: ${({ theme }) => theme.colors.primary};
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    min-height: 615px;
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    min-height: 690px;
  }
`;

export const Video = styled.video`
  width: 100%;
  object-fit: fill;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    min-height: 690px;
  }
`;

export const Info = styled.div`
  max-width: 680px;
  margin: 0 20px;
  color: ${({ theme }) => theme.colors.cream};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    margin: 0;
    max-width: 680px;
    position: absolute;
    top: 85px;
    left: 75px;
  }
`;

export const Title = styled.h1<TitleProps>`
  margin-top: 20px;
  font: ${({ theme }) => theme.typography.mobile.headingM};
  color: ${({ theme }) => theme.colors.cream};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobileM}) {
    font: ${({ theme }) => theme.typography.mobile.headingXL};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    margin-top: 0;
    font: ${({ theme }) => theme.typography.desktop.headingXXL};
    max-width: ${({ variant }) => variant === LandingVariant.crypto && "600px"};
  }
`;

export const SubTitle = styled.p<SubtitleProps>`
  width: 100%;
  margin-top: 20px;
  font: ${({ theme }) => theme.typography.desktop.paragraphXXLShort};
  font-family: ${({ theme }) => theme.fontFamily.interFont};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    width: ${({ variant }) => (variant === LandingVariant.lma ? "62%" : "69%")};
    margin-top: 15px;
  }
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  margin: 50px 0 40px 0;
  display: flex;
  flex-wrap: wrap;

  a.link-button {
    white-space: nowrap;
    margin-bottom: 20px;
  }

  a.link-button:nth-of-type(1) {
    margin-right: 10px;
  }

  button {
    white-space: nowrap;
    margin-bottom: 20px;
    margin-right: 10px;
    padding: 22px 15px 19px 15px;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobileM}) {
      padding: 22px 20px 19px 20px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobileL}) {
      padding: 22px 40px 19px 40px;
      margin-right: 20px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    a.link-button:nth-of-type(1) {
      margin-right: 20px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopS}) {
    width: 70%;
    margin: 50px 0 0 0;
  }
`;

export const ScrollTo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 40px 19px 40px;
  max-height: 45px;
  font: ${({ theme }) => theme.typography.desktop.button};
  border: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 80px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.cream};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.cream};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.violet};
    border-color: ${({ theme }) => theme.colors.violet};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mobileM}) {
    max-height: 55px;
  }
`;

import React, { useMemo, lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

import animationDesktop from "../../assets/animations/mm_main_animation.mp4";
import animationMobile from "../../assets/animations/mm_main_animation_mobile.mp4";
import avatar from "../../assets/avatar-aaron.jpeg";
import { HeroBanner } from "../../components/HeroBanner";
import { Layout } from "../../components/Layout";
import banner from "../../assets/mm_bottom__banner.png";
import loadingIcon from "../../assets/icons/benefit-loading.svg";
import processIcon from "../../assets/icons/benefit-process.svg";
import innovationIcon from "../../assets/icons/benefit-innovation.svg";
import subtractIcon from "../../assets/icons/benefit-subtract.svg";
import { LandingVariant } from "../../shared/enum";
import { links } from "../../shared/links";
import { BoxSpinner } from "../../components/BoxSpinner";
import { getAnimation } from "../../helpers/getAnimation";
import { Head } from "../../components/Head";

const WhySection = lazy(() => import("../../components/WhySection/WhySection"));
const ProductsSection = lazy(
  () => import("../../components/ProductsSection/ProductsSection")
);
const Benefits = lazy(() => import("../../components/Benefits/Benefits"));
const Banner = lazy(() => import("../../components/Banner/Banner"));
const Intercom = lazy(() => import("../../components/Intercom/Intercom"));
const ModalSignup = lazy(
  () => import("../../components/ModalSignup/ModalSignup")
);

const { contact } = links;

const Homepage = (): JSX.Element => {
  const { t } = useTranslation();
  const { main } = LandingVariant;
  //  Add when testing phase will be done
  // const userInfo = useSelector(selectStateUserInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const benefits = useMemo(() => {
    return [
      {
        imageSrc: processIcon,
        imageAlt: "process icon",
        name: t("homepage.benefitsSection.benefits.security.name"),
        about: t("homepage.benefitsSection.benefits.security.info"),
        width: "550px",
      },
      {
        imageSrc: loadingIcon,
        imageAlt: "loading icon",
        name: t("homepage.benefitsSection.benefits.transparency.name"),
        about: t("homepage.benefitsSection.benefits.transparency.info"),
      },
      {
        imageSrc: innovationIcon,
        imageAlt: "innovation icon",
        name: t("homepage.benefitsSection.benefits.innovation.name"),
        about: t("homepage.benefitsSection.benefits.innovation.info"),
      },
      {
        imageSrc: subtractIcon,
        imageAlt: "subtract icon",
        name: t("homepage.benefitsSection.benefits.availability.name"),
        about: t("homepage.benefitsSection.benefits.availability.info"),
        width: "550px",
      },
    ];
  }, [t]);

  const animation = getAnimation(animationDesktop, animationMobile);
  const openModal = () => setIsModalOpen(true);

  return (
    <>
      <Head name="Moon Mortgage" />
      {/*    Remove when testing phase will be done */}
      <ModalSignup isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <Layout buttonOnClick={openModal}>
        {/*    Replace buttonOnClick with buttonUrlMain when testing phase will be done */}
        <HeroBanner
          variant={main}
          animation={animation}
          title={t("homepage.heroBanner.title")}
          subtitle={t("homepage.heroBanner.subtitle")}
          buttonNameMain={t("homepage.heroBanner.buttons.signup")}
          buttonOnClickMain={openModal}
          buttonNameSecondary={t("homepage.heroBanner.buttons.see")}
          buttonUrlSecondary="our-products"
        />
        <Suspense fallback={<BoxSpinner />}>
          <WhySection
            subtitle={t("homepage.whySection.subtitle")}
            title={t("homepage.whySection.title")}
            name={t("homepage.whySection.name")}
            role={t("homepage.whySection.role")}
            avatar={avatar}
          />
          {/*    Remove buttonOnClick when testing phase will be done */}
          <ProductsSection buttonOnClick={openModal} />
          <Benefits
            title={t("homepage.benefitsSection.title")}
            benefits={benefits}
          />
          <Banner
            variant={main}
            title={t("homepage.banner.title")}
            subtitle={t("homepage.banner.subtitle")}
            buttonName={t("homepage.banner.button")}
            buttonUrl={contact}
            backgrundImage={banner}
            backgrundAlt="banner image"
          />
          <Intercom active />
        </Suspense>
      </Layout>
    </>
  );
};

export default Homepage;

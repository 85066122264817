import React from "react";

import { SpinnerContainer } from "./Spinner.styles";

interface SpinnerProps {
  black?: boolean;
}

const Spinner = ({ black }: SpinnerProps): JSX.Element => {
  return (
    <SpinnerContainer black={black}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </SpinnerContainer>
  );
};

export default Spinner;

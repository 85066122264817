import styled from "styled-components";

interface ContainerProps {
  routes?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${({ routes }) => (routes ? "100vh" : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
`;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StateProps, WhitelistedWalletsResponse } from "./models";
import {
  InstitutionTypeAnswers,
  RecipientTypeAnswers,
  WhitelistedWallet,
} from "../components/ModalAddWallet/models";
import { getRequest } from "../helpers/axios";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: WhitelistedWalletsResponse = {
  count: null,
  next: null,
  previous: null,
  results: [
    {
      id: 0,
      name: "",
      network_id: "",
      address: "",
      location: InstitutionTypeAnswers.PRIVATE,
      institution_name: "",
      institution_country: "",
      unhosted_description: "",
      recipient_type: RecipientTypeAnswers.MYSELF,
      other_recipient_details: {
        first_name: "",
        last_name: "",
        full_name: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        zip_code: "",
        state: "",
        country: "",
      },
      whitelisting_status: "",
    },
  ],
};

export const whitelistedWalletsFetch = async (
  token: string
): Promise<WhitelistedWalletsResponse | null> => {
  const res = getRequest(`${API_URL}/kyc/whitelisted_wallets`, token)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return initialState;
    });

  return res;
};

export const getWhitelistedWallets = createAsyncThunk(
  "getWhitelistedWallets",
  async (token: string): Promise<WhitelistedWalletsResponse | null> => {
    const response = await whitelistedWalletsFetch(token);
    return response;
  }
);

export const whitelistedWalletsSlice = createSlice({
  name: "whitelistedWallets",
  initialState,
  reducers: {
    addWallet(state, action: PayloadAction<WhitelistedWallet>) {
      state.count = state.count ? state.count + 1 : 1;
      state.results = [...state.results, action.payload];
    },
    updateWallet(state, action: PayloadAction<WhitelistedWallet>) {
      state.results = state.results.map((item) =>
        item.id === action.payload.id
          ? { ...item, name: action.payload.name }
          : item
      );
    },
    removeWallet(state, action: PayloadAction<number>) {
      state.count = state.count ? state.count - 1 : 0;
      state.results = state.results.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWhitelistedWallets.pending, (state) => {
      return { ...state };
    });

    builder.addCase(
      getWhitelistedWallets.fulfilled,
      (state, action: PayloadAction<WhitelistedWalletsResponse | null>) => {
        if (action.payload === null) {
          return state;
        }
        const newState = { ...action.payload };

        return { ...newState };
      }
    );
  },
});

export const selectStateWhitelistedWallets = (state: StateProps) =>
  state.whitelistedWallets;

export const actions = {
  ...whitelistedWalletsSlice.actions,
  getWhitelistedWallets,
};
